import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { DataRoutes, Pages } from '../utils/routes.utils';
import Landing from './landing.screen';
import { Container } from '@mui/material';
import Home from './home.screen';
import HomePage from './homepage';
import Tabbar from '../components/tabbar.component';
import Programmation from './programmation.screen';

const Screens = React.memo(() => {

  return (
    <>
    <Container style={{padding: 0, margin: 0, maxWidth: 'unset', height: '100%'}}>
      <Tabbar routes={DataRoutes.routes}/>
      <Routes>
        {/* <Route path='*' element={<Navigate to={Pages.LANDING} replace />} /> */}
        <Route path='*' element={<Navigate to={Pages.HOMEPAGE} replace />} />
        <Route element={<Landing/>} path={Pages.LANDING} />
        <Route path={Pages.HOME} element={<Home/>} />
        <Route path={Pages.PROG} element={<Programmation/>} />
        <Route path={Pages.HOMEPAGE} element={<HomePage/>} />
      </Routes>
    </Container>
    </>
  )
});

export default Screens;