import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import Player from '../components/player.component';
import { Colors } from '../constants/colors.constants';
import Chat from '../components/chat.component';
import { alpha } from '@material-ui/core';

const Home = () => {

  return (
    <Stack p={4} height='100%' style={{backgroundColor: Colors.semiPink}} gap={3}>
      {/* <Player/> */}
      <Typography fontSize={24} fontWeight={800} color={Colors.pink}>
        Nala
      </Typography>
      <Typography>The Chow Chow is a spitz-type of dog breed originally 
        from Northern China. The Chow Chow is a sturdily built dog, 
        square in profile, with a broad skull and small, triangular, erect 
        ears with rounded tips. The breed is known for a very dense double coat 
        that is either smooth or rough. The fur is particularly thick in 
        the neck area, giving it a distinctive ruff or mane appearance. 
        The coat may be shaded/self-red, black, blue, cinnamon/fawn, 
        or cream.</Typography>
        <Grid 
          container 
          gap={2} 
          height='100%' 
          justifyContent='space-between' 
          direction='row'>
          <Grid 
            item 
            xs 
            style={{
              backgroundColor: `${alpha('#FFFFFF', 0.3)}`
            }}>
          </Grid>
          <Grid item xs overflow='scroll'>
            <Stack height='100%'>
              <Chat/>
            </Stack>
          </Grid>
        </Grid>
    </Stack>
  )
}

export default Home;