import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ProgrammationDto } from '../store/programmation/programmation.models'
import ProgCard from '../components/progCard.component';
import Header from '../components/header.component';

const Programmation = () => {

  const prog: ProgrammationDto[] = [
    { 
      date: '03/04/2024', 
      location: 'Espace Julien', 
      types: '[LIVE, CLUB]',
      artists: [
        { name: 'Lugal Lanbada', type: 'hybride set' },
        { name: 'Maquis Son Sistèm', type: 'live' },
        { name: 'Ksu b2b Trae Joly', type: 'dj set' }
      ],
      price: 'Gratuit sur réservation', 
      link: 'https://billetterie.espace-julien.com/agenda/717-l-ej-c-est-le-s-x-folks-festival',
      time: '21h-01h'
    },
    { 
      date: '06/04/2024', 
      location: "L'Embobineuse",
      types: '[LIVE, CLUB]',
      artists: [
        { name: 'Emmanuelle Parrenin', type: 'live' },
        { name: 'Caïn و Muchi', type: 'hybride set' },
        { name: 'Paloma Colombe' },
        { name: 'Desastre' }
      ],
      price: '9€', 
      link: 'https://www.helloasso.com/associations/l-embobineuse/evenements/folks-festival-emmanuelle-parrenin-cain-et-muchi-paloma-colombe-desastre',
      time: '21h-02h'
    },
    { 
      date: '10/04/2024', 
      location: 'Espace Projet Club Coop', 
      types: '[TALK, CLUB]',
      artists: [
        { name: 'Renaud Brizard', type: 'table ronde + dj set' },
        { name: 'Mystique', type: 'table ronde' },
        { name: 'Mona HKL', type: 'table ronde + dj set' },
        { name: 'Lugal Lanbada', type: 'table ronde + dj set' },
      ],
      price: 'Prix libre sur réservation', 
      link: 'https://www.helloasso.com/associations/folks-festival/evenements/folks-talk',
      time: '18h-01h30'
    },
    { 
      date: '12/04/2024', 
      location: 'Ateliers Jeanne Barret', 
      types: '[LIVE, CLUB]',
      artists: [
        { name: 'Mona HKL' },
        { name: 'René Danger', type: 'live' },
        { name: 'Syqlone سايكلون', type: 'live' },
        { name: ' Ojoo (fka Ojoo Gyal) b2b Mystique', type: 'dj set' }
      ],
      price: '10€', 
      link: 'https://www.helloasso.com/associations/folks-festival/evenements/folks-soiree-de-cloture',
      time: '22h-04h30'
    }
  ]

  return (
    <Stack alignItems='center' justifyContent='center' gap={4} pt={4} pb={4} minWidth='80%'>
      <Stack justifyContent='center' alignItems='center' p={4} gap={2}>
          <Header/>
        </Stack>
      <Stack gap={4} minWidth='80%' maxWidth='90%' alignItems='center' justifyContent='center'>
        {prog.map((it) => (
          <ProgCard programmation={it} />
        ))}
      </Stack>
    </Stack>
  )
}

export default Programmation;