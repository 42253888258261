import React from 'react';
import { Typography, Stack } from '@mui/material';
import { Colors } from '../constants/colors.constants';

import Logo2 from '../assets/images/Logo2.svg';
import Logo3 from '../assets/images/Logo3.svg';
import LogoPurple from '../assets/images/LogoPurple.svg';
import Arrow from '../assets/icons/ArrowPurple.svg';

const Header = () => {
  return (
    <>
      <Typography  color={Colors.folksPurple} fontFamily='DroulersClarendon-Line' fontSize={80}>FOLKS</Typography>
      <Stack direction='row' gap={1} sx={{display: {xs: 'none', md: 'flex'}}}>
        <img src={Logo2} alt='' style={{height: '180px'}} />
        <img src={LogoPurple} alt='' style={{height: '180px'}} />
        <img src={Logo3} alt='' style={{height: '180px'}} />
      </Stack>
      <Stack direction='row' gap={1} sx={{display: {xs: 'flex', md: 'none'}}}>
        <img src={LogoPurple} alt='' style={{height: '200px'}} />
      </Stack>
      <Stack alignItems='center'>
        <Typography color={Colors.folksPurple} fontFamily='DroulersClarendon-LineItalic' fontSize={22} align='center'>
          Festival des musiques traditionnelles revisitées.
        </Typography>
        <Typography color={Colors.folksPurple} fontSize={70} fontFamily='Mistral'>
          Marseille
        </Typography>
        <Typography color={Colors.folksPurple} fontFamily='DroulersClarendon-LineItalic' fontSize={{xs: 28, md: 36}}>
          03 
          {/* <img src={Arrow} alt=''/>  */}
          →12.04.2024
        </Typography>
      </Stack>
    </>
  )
}

export default Header;