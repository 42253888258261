import React from 'react';
import { Button, Stack } from '@mui/material';
import { Pages } from '../utils/routes.utils';
import { useNavigate } from 'react-router-dom';

const Landing = () => {

  const navigate = useNavigate();

  return (
    <Stack 
      direction='column' 
      justifyContent='center' 
      alignItems='center' 
      height={'100%'}
      p={3} 
      gap={3}
      style={{backgroundColor: 'pink'}}>
        <pre style={{color: 'red', fontSize: '20px', fontWeight: '500', border: '0px solid red'}}>
          8888b.   dP"Yb    dP""b8  .dP"Y8 88 888888 888888 888888 88""Yb   8888b.  88   88     .d 88888 <br/>
          8I  Yb dP     Yb dP   `"  `Ybo." 88   88     88   88__   88__dP    8I  Yb 88   88   .d88   .dP <br/>
          8I  dY Yb     dP Yb  "88  o.`Y8b 88   88     88   88""   88"Yb     8I  dY Y8   8P     88 o `Yb <br/>
          8888Y"   YbodP    YboodP  8bodP' 88   88     88   888888 88  Yb   8888Y"  `YbodP'     88 YbodP <br/>
        </pre>
        <Stack direction='row' color='red' gap={2} justifyContent='center'>
          <pre style={{fontSize: '8px', alignSelf: 'center', alignContent: 'start'}}>                                                                                        
                  -++=:                                                                      <br/>
                .****+=-                                                                    <br/>
                :=*##*+++:                                                                  <br/>
                :-=*##*+**+                                                                 <br/>
              :---+###**#*+.                                                               <br/>
              :===+#%%##%#**:                                                              <br/>
              :====#%%%%%#+++-                                                             <br/>
              .=++++%%%%%*****=::.::-:...                        .:-===--::.               <br/>
                -=++=*%%%%###*=--=+++==-::----::..            :-+**##%%%####%#=:            <br/>
                -===+#%##*++=**#=+*=-=+===---::-:::::::++++*###*#%%%%%%%%@@@@@%*:          <br/>
                =##*++##***=+#####++==+**##*+=--:::..::=**#%##%%@@@@@@@@@@@@@@@@#.         <br/>
                .=++==+*%@@%###*#*****##***#*=-:::-:.::-*%#%%#%%@@@@@@@@@@@@@@@@#          <br/>
                  :---=**%%@%##*++****+*=+***##*+-:--::--+##%%%%%%%@@@@@@@@@@@@%=.          <br/>
                    :-**###++++=--==**+*++*#%@%%@%*--::--=###%%%%@@@@@@@@@@@@@#:            <br/>
                    =#***+==------=+===+++*#%@%%##*------*%%%@@@@@@@@%%%@@%#-              <br/>
                    .*+++++===----====-==--++*****##**+==+**#%%%#*###%%%%*:                <br/>
                    .=++++==++*=-========--=+++**######**#*****+=-=+#%#%=.                  <br/>
                  .%@@%%%%##**#+===+++====++#%%%#++***#*#%%##*+++=-=-.                     <br/>
                  .*@@@@@@@@@@%%+==-=+++====++##*++++*####%@#*+-..::.                       <br/>
                .+#%@%@@@@@@@%*=-===+*+++++=-===*****####*-:.                              <br/>
                :##%@@@@@@@@%#++====+*****+==-=*%%%######:                                 <br/>
                :%%%%@@@@@@%@%##*+******+*#****%%%%%#####-                                 <br/>
                  %%%%@@@@@@@@@%%%%%##*+*##%%##%%%%%%####%=                                 <br/>
                  -*%%%@@@@@@@@@@@%%%%***#%%###%%%%%%##*%%=                                 <br/>
                    :#%%%%%@@@@@%%%%%##%%%%%#%#%%%%####%@@=                                 <br/>
                    .#%%%%@@@@@@@@@@%@@@@%%%%%%%%%##%%@@%+                                 <br/>
                    :#%#**%%%@@%%%@@@@@@%%@@@@@@@%%%%%@%#-                                 <br/>
                    =%%#**##%%@@@@%%%%%%%%@@@%%%@%%%%%%%=                                  <br/>
                    +%##++#%%@@@@@%@@%%%%%%%%%@@%%%@%%##=                                  <br/>
                    *###**#%%@@@@@%%%%%%%%%%%@@%@@@%%###+                                  <br/>
                    *#####%%%@%%@%%##%#####%%@@@@@@@%%##=                                  <br/>
                    :+*######*+*%%#####**##%%%%%%%%#*=:.                                   <br/>
          </pre>
          <p style={{color: 'brown', fontSize: '16px', width: '50%', alignSelf: 'center'}}>
            Passionné par les animaux depuis toujours, je suis actuellement Dog Sitter 
            avec une année d'expérience, ayant pris soin d'une vingtaine de chiens avec
            dévouement et attention. Ma priorité est d'assurer le bien-être et la sécurité
            des animaux qui me sont confiés, en veillant à recréer un environnement chaleureux 
            et familial en votre absence. Grâce à ma connaissance approfondie des besoins canins, 
            je m'engage à offrir une expérience positive à vos compagnons à quatre pattes. 
            Ma flexibilité, mon sens des responsabilités et ma capacité à établir une connexion 
            instantanée avec les animaux font de moi un Dog Sitter dévoué et fiable. Je serais 
            honoré de mettre mes compétences au service de votre famille et de contribuer au 
            bonheur de vos fidèles amis.
            <br/>
            <br/>
            Cordialement,
          </p>
        </Stack>
          <Button style={{border: '1px solid red', color: 'red'}} onClick={() => navigate('/home')}>
            entrer
          </Button>
    </Stack>
  )
}

export default Landing;