// @ts-ignore
import Verdana from './verdana/Verdana.ttf'

// @ts-ignore
import VerdanaBold from './verdana/VerdanaBold.ttf'

// @ts-ignore
import Georgia from './georgia/Georgia.ttf'

// @ts-ignore
import GeorgiaBold from './georgia/GeorgiaBold.ttf'

// @ts-ignore
import Mistral from './mistral/Mistral.ttf'

// @ts-ignore
import DroulersClarendonLine from './droulers/DroulersClarendon-Line.ttf'
// import DroulersClarendonLine from './georgia/GeorgiaBold.ttf'

// @ts-ignore
import DroulersClarendonLineItalic from './droulers/DroulersClarendon-LineItalic.ttf'

const fonts = `
  @font-face {
    font-family: "Verdana";
    font-weight: 800;
    src: local("VerdanaBold"),
    url(${VerdanaBold});
  }
  @font-face {
    font-family: "Verdana";
    font-weight: 500;
    src: local("Verdana"),
    url(${Verdana});
  }
  @font-face {
    font-family: "Georgia";
    font-weight: 800;
    src: local("GeorgiaBold"),
    url(${GeorgiaBold});
  }
  @font-face {
    font-family: "Georgia";
    font-weight: 500;
    src: local("Georgia"),
    url(${Georgia});
  }
  @font-face {
    font-family: "Mistral";
    font-weight: 1 1000;
    src: local("Mistral"),
    url(${Mistral});
  }
  @font-face {
    font-family: "DroulersClarendon-Line";
    font-weight: 1 1000;
    src: local("DroulersClarendon-Line"),
    url(${DroulersClarendonLine});
  }
  @font-face {
    font-family: "DroulersClarendon-LineItalic";
    font-weight: 1 1000;
    src: local("DroulersClarendon-LineItalic"),
    url(${DroulersClarendonLineItalic});
  }
`
export default fonts;