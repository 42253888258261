import React from 'react';
import { Stack, Typography } from '@mui/material';

interface ChatProps {
  color?: string;
  secondaryColor?: string;
}

const Chat = (props: ChatProps) => {

  const { color, secondaryColor } = props;

  return (
    <Stack style={{border: `1px solid white`}} height='100%'>
      
    </Stack>
  )
}

export default Chat;