import { createTheme } from '@mui/material';
import { Colors } from '../constants/colors.constants';
import card from './card.theme';
import button from './button.theme';
import fonts from '../assets/fonts/index';

const theme = createTheme({
  typography: {
    // fontFamily: 'Georgia'
    fontFamily: 'DroulersClarendon-Line'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `${fonts}
      html {
        height: 100%;
        // -webkit-font-smoothing: auto;
      }
      body {
        height: 100%;
        scrollbar-width: thin;
      }
      div#root {
        height: 100%;
        white-space: pre-line;
      }
      `
    },
    ...button,
    ...card,
  }
});

export default theme;