import { Components } from '@mui/material';


const card: Components = {
  MuiCard: {
    styleOverrides: {
      root: (props) => ({
        boxShadow: 'none',
        borderRadius: 5,
        ...(props).ownerState['aria-label'] === 'player' ? {
          height: 15,
          width: 150
        } : {}
      })
    }
  }
}

export default card;