import { Components } from '@mui/material';

const button: Components = {
  MuiButton: {
    styleOverrides: {
      root: {
        minWidth: 0,
        fontFamily: 'DroulersClarendon-Line',
        textTransform: 'none'
      }
    }
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        fontFamily: 'DroulersClarendon-Line',
      }
    }
  }
}

export default button;