import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { ProgrammationDto } from '../store/programmation/programmation.models'
import { Colors } from '../constants/colors.constants';

import Arrow from '../assets/icons/Arrow.svg';
import ArrowYellow from '../assets/icons/ArrowYellow.svg';
import ArrowPurple from '../assets/icons/ArrowPurple.svg';

interface ProgCardProps {
  programmation: ProgrammationDto;
}

const ProgCard = (props: ProgCardProps) => {

  const { programmation } = props;

  return (
    <Stack 
      gap={2}
      style={{
        borderRadius: '20px', 
        border: `1px solid ${Colors.folksPurple}`
      }} 
      p={2} 
      width='100%'>
      <Stack direction={{xs: 'column', md: 'row'}} justifyContent='space-between'>
        <Typography 
          fontSize={20}
          color={Colors.folksPurple}>
          {programmation.date} - {programmation.location}
        </Typography>
        <Typography color={Colors.folksPurple} fontSize={20}>{programmation.time}</Typography>
      </Stack>
      <Stack gap={1}>
        <Typography color={Colors.folksPurple} fontFamily='DroulersClarendon-LineItalic'>{programmation.types}</Typography>
        <Stack>
          {programmation.artists.map((it) => (
            <Stack direction='row' gap={1} flexWrap='nowrap'>
              <Typography 
                color={Colors.folksPurple} 
                fontSize={{xs: 16, md: 22}}>
                  {it.name}
              </Typography>
             {it.type && ( 
              <Typography 
                color={Colors.folksPurple} 
                fontSize={14} 
                fontFamily={'DroulersClarendon-LineItalic'}>
                {`(${it.type})`}
              </Typography>)}
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack direction='row' alignItems='center' justifyContent={'space-between'} gap={{xs: 0, md: 2}}>
        <Typography color={Colors.folksPurple}>
          {programmation.price ? programmation.price : ''}
        </Typography>
        <Stack sx={{flex: 1, backgroundColor: Colors.folksPurple, height: '1px', width: '100%', visibility: { xs: 'hidden', md: 'visible'} }} />
        {programmation.link && (
          <Button
            onClick={() => {
              window.location.href = programmation.link || ''
            }}
            sx={{
              textTransform: 'uppercase',
              backgroundColor: Colors.folksYellow,
              color: Colors.folksPurple,
              paddingLeft: '10px',
              paddingRight: '10px',
              borderRadius: '10px',
              xs: { width: '150px', maxWidth: '150px', fontSize: '14px' },
              md: { width: '250px', fontSize: '22px', maxWidth: '250px', minWidth: '250px' }

            }}
            endIcon={
              <img src={ArrowPurple} style={{width: '20px'}} alt='' />
            }
            >Réserver
          </Button>
          )}
          {/* <iframe id="haWidget" allowTransparency src="https://www.helloasso.com/associations/folks-festival/evenements/folks-soiree-de-cloture/widget-bouton" style={{width: '100%', height: '70px', border: 'none'}}></iframe> */}
      </Stack>
    </Stack>
  )
}

export default ProgCard;