import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import ArrowYellow from '../assets/icons/ArrowYellow.svg';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../constants/colors.constants';
import Header from '../components/header.component';

import Logo2 from '../assets/images/Logo2.svg';
import Logo3 from '../assets/images/Logo3.svg';
import LogoPurple from '../assets/images/LogoPurple.svg';


const HomePage = () => {

  const navigate = useNavigate();

  return (
    <Stack alignItems='center' gap={4} pt={4} pb={4}>
      <Stack gap={3} maxWidth='800px'>
        <Stack justifyContent='center' alignItems='center' p={4} gap={2}>
          <Header/>
          <Typography color={Colors.folksPurple} fontFamily='DroulersClarendon-Line' fontSize={{xs: 18, md: 22}} align='center'>
          Cette première édition se décline sur 4 soirées dans 4 lieux différents autour d’une intention : mettre en lumière des projets de musiques actuelles qui forgent leurs identités dans un patrimoine revendiqué. <br/><br/>

          Des concerts, discussions et soirées clubs où s'entremêlent instruments traditionnels, chants contestataires et basses fréquences dans une exploration hypnotique comme une ouverture à l’autre. 
          En questionnant nos passés et nos sonorités modernes, FOLKS s’engage à convoquer les histoires et les terroirs pour sublimer ces artistes qui donnent du sens aux dancefloors. 

          </Typography>
        </Stack>
        <Stack style={{borderRadius: '20px', border: `1px solid ${Colors.folksPurple}`}} p={3} m={2}>
          <Typography color={Colors.folksPurple} fontFamily='DroulersClarendon-Line' fontSize={{xs: 16, md: 28}} align='center'>
          Espace Julien - L’Embobineuse <br/>Jeanne Barret - Espace Projet Club Coop
          </Typography>
        </Stack>
      </Stack>
      <Stack style={{backgroundColor: Colors.folksYellow}} width='100%' p={6} alignItems='center' mt={4} position='relative'>
        <Stack direction='row' position='absolute' top={'-20px'} gap={2}>
          <div style={{backgroundColor: `${Colors.folksPurple}`, height: '40px', width: '40px', borderRadius: '20px'}}></div>
          <div style={{backgroundColor: `${Colors.folksPurple}`, height: '40px', width: '40px', borderRadius: '20px'}}></div>
          <div style={{backgroundColor: `${Colors.folksPurple}`, height: '40px', width: '40px', borderRadius: '20px'}}></div>
        </Stack>
        <Stack maxWidth='700px' alignItems='center' justifyContent='center'>
          <Typography fontFamily='DroulersClarendon-Line' color={Colors.folksPurple} fontSize={{xs: 20, md: 30}} align='center'>
            EMMANUELLE PARRENIN (Live)<br/>
            CAÏN و MUCHI (Hybride set)<br/>
            DESASTRE<br/>
            DJ FULL OPTION<br/>
            KSU b2b TRAE JOLY<br/>
            LUGAL LANBADA (Dj set commenté)<br/>
            MAQUIS SON SISTÈM (Live)<br/>
            MONA HKL<br/>
            OJOO b2b MYSTIQUE<br/>
            PALOMA COLOMBE<br/>
            RENÉ DANGER (Live)<br/>
            SYQLONE سايكلون (Live)<br/>
          </Typography>
        </Stack>
        <Stack direction='row' position='absolute' bottom={'-20px'} gap={2}>
          <div style={{backgroundColor: `${Colors.folksPurple}`, height: '40px', width: '40px', borderRadius: '20px'}}></div>
          <div style={{backgroundColor: `${Colors.folksPurple}`, height: '40px', width: '40px', borderRadius: '20px'}}></div>
          <div style={{backgroundColor: `${Colors.folksPurple}`, height: '40px', width: '40px', borderRadius: '20px'}}></div>
        </Stack>
      </Stack>
      {/* <Stack maxWidth='700px' p={4}>
        <Typography fontSize={{xs: 16, md: 20}} align='center' color={Colors.folksPurple}>
          FOLKS se présente comme un festival audacieux, plongeant 
          au cœur des traditions musicales pour explorer leur 
          réinterprétation dans notre monde contemporain. Du 3 au 12 
          avril 2024, ce festival ambitionne de tisser un dialogue 
          harmonieux entre les Musiques Traditionnelles et Actuelles à 
          Marseille, à travers une riche programmation d'événements tels 
          que des soirées, concerts, performances. Cette première édition 
          se tiendra à Jeanne Barret, L’Embobineuse, l’Espace Julien, 
          et Club Coop. En unissant des voix décoloniales, des créateur·ices 
          de contre-cultures et des artistes engagé·es, le festival offre 
          une expérience musicale unique et un temps de réflexion sur 
          l'identité, l'authenticité et l'appropriation culturelle.
        </Typography>
      </Stack> */}
      <Button
        onClick={() => navigate('/programmation')}
        style={{
          textTransform: 'uppercase',
          backgroundColor: Colors.folksPurple,
          color: Colors.folksYellow,
          fontSize: '22px',
          paddingLeft: '10px',
          paddingRight: '10px',
          borderRadius: '10px',
          marginTop: '3rem',
          width: '300px'
        }}
        endIcon={
          <img src={ArrowYellow} style={{width: '20px'}} alt='' />
        }>Programmation</Button>
      <Button
        onClick={() => {
          window.location.href='mailto:contact.folksfestival@gmail.com'
        }}
        style={{
          paddingTop: '15px',
          color: Colors.folksPurple,
          fontSize: '16px',
          paddingLeft: '10px',
          paddingRight: '10px',
          borderRadius: '10px',
          textDecoration: 'underline',
          width: '300px'
        }}>{`Nous contacter`}</Button>
    </Stack>
  )
}

export default HomePage;