import React, { useState } from 'react';
import { Stack, Button, IconButton } from '@mui/material';
import { Pages, useRouteMatch } from '../utils/routes.utils';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../constants/colors.constants';

import Instagram from '../assets/icons/instagram.svg';
import Facebook from '../assets/icons/facebook.svg';

interface TabbarProps {
  routes: { label: string, path: string }[];
  handleChange?: () => void;
}

const Tabbar = (props: TabbarProps) => {

  const { routes, handleChange } = props;

  const currentTab = useRouteMatch(routes.map((route) => route.path));

  const navigate = useNavigate();

  return (
    <Stack 
      direction='row' 
      alignItems='center'
      padding={4} 
      justifyContent='center' 
      gap={1}
      style={{backgroundColor: Colors.folksYellow}}
      >
      <Stack direction='row' position={{xs: 'relative', sm: 'absolute'}} sx={{left: {sm: '20px'}}} gap={1}>
        <IconButton 
          onClick={() => {
            window.location.href = 'https://www.instagram.com/folksfestivalmarseille/'
          }} 
          style={{
            height: '30px', 
            width: '30px'
          }}>
          <img src={Instagram} alt='' 
          style={{
            height: '30px', 
            width: '30px'
          }}/>
        </IconButton>
        <IconButton 
          onClick={() => {
            window.location.href = 'https://www.facebook.com/profile.php?id=61556886173321'
          }}
          style={{
            height: '30px', 
            width: '30px'
          }}>
          <img src={Facebook} alt='' 
          style={{
            height: '30px', 
            width: '30px'
          }}/>
        </IconButton>
      </Stack>
      <Button 
        onClick={() => navigate('/homepage')}
        style={{
          textDecoration: currentTab === Pages.HOMEPAGE ? 'underline' : 'none',
          color: Colors.folksPurple
        }}>
        Accueil
      </Button>
      <Button 
        onClick={() => navigate('/programmation')}
        style={{
          textDecoration: currentTab === Pages.PROG ? 'underline' : 'none',
          color: Colors.folksPurple
        }}>
        Programmation
      </Button>
    </Stack>
  )
}

export default Tabbar;