import { alpha } from '@mui/material'

export const Colors: any = {
  semiRed: `${alpha('#FF0000', 0.65)}`,
  red: '#FF0000',
  white: '#FFFFFF',
  black: '#OOOOOO',
  pink: '#FFOOB8',
  semiPink: `${alpha('#FF00B8', 0.5)}`,
  yellow: '#FFF500',
  semiYellow: `${alpha('#FFF500', 0.65)}`,
  aquaBlue: '#00D1FF',
  semiAquaBlue: `${alpha('#00D1FF', 0.65)}`,
  royalBlue: '#001AFF',
  semiRoyalBlue: `${alpha('#001AFF', 0.65)}`,
  orange: '#FF5C00',
  semiOrange: `${alpha('#FF5C00', 0.65)}`,

  folksPurple: '#4b078b',
  folksYellow: '#faef65',
  folksSemiPurple: `${alpha('#4b078b', 0.15)}`,
  folksSemiYellow: `${alpha('#faef65', 0.15)}`,
}